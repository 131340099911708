/*landing pg,add property pg and register login*/

:root {
  --blue: #1e90ff;
  --white: #ffffff;
}

::-webkit-scrollbar {
  width: 0.6rem;
  background: #413e3e;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background: #3cd4a5;
}

#root {
  overflow-x: hidden;
}

#root .padding {
  padding: 5% 6% 0%;
  overflow: hidden;
  border-radius: 1rem;
}

/* 500px media query */
@media screen and (max-width: 500px) {
  #root .padding {
    padding-top: 8%;
  }
  #servhed {
    margin-bottom: 2rem !important;
  }
}

p {
  font-size: 1.25rem;
  font-family: "Noto Sans", sans-serif;
}

.ulclass {
  margin-top: 1rem;
  font-family: "Noto Sans", sans-serif;
}

.ulclass Link {
  font-family: "Noto Sans", sans-serif;
}

nav {
  background: #fff;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  width: 100%;
}
nav:after {
  content: "";
  clear: both;
  display: table;
}

nav .logo {
  float: left;
  line-height: 0px;
  padding-left: 0px;
  margin-top: 1.25rem;
}

.logo img {
  width: 10rem !important;
  object-fit: contain;
}

nav ul {
  z-index: 1;
  float: right;
  list-style: none;
  margin-right: 2.5rem;
  position: relative;
}
nav ul li {
  float: left;
  display: inline-block;
  margin: 0 0.4rem;
}
nav ul li a {
  font-weight: 900;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  transition: 0.3s ease;
}
nav ul li a:hover {
  color: #fff;
}

.postpropertyheading {
  font-weight: 700;
  color: #025a63;
  font-family: "Barlow Condensed", sans-serif;
}
.postpropertyheading:hover {
  letter-spacing: 0.5px;
  font-weight: 700;
  color: #000;
}

nav ul li:hover > ul {
  top: 70px;
  opacity: 1;
  visibility: visible;
}

.login {
  background: #025a63;
}

.show,
.icon,
.hide {
  display: none;
}
@media all and (max-width: 786px) {
  nav ul {
    margin-right: 0px;
    float: center;
  }
  nav .logo {
    width: 100%;
  }
  nav ul li,
  .show {
    display: block;
    color: white;
    font-size: 1rem;
    padding: 0 1.25rem;
    line-height: 70px;
    cursor: pointer;
  }
  .show:hover {
    color: cyan;
  }
  .icon {
    display: block;
    color: black;
    position: absolute;
    right: 2.5rem;
    line-height: 70px;
    font-size: 1.5rem;
    cursor: pointer;
  }
  nav ul li a:hover {
    box-shadow: none;
  }
  .show + a,
  .ulclass {
    display: none;
  }
  [id^="btn"]:checked + ul {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    text-align: center;
  }
  p {
    font-size: 1rem;
  }
}

/*navbar ends*/

h1 {
  margin-top: 2%;
  color: #025a63;
  font-weight: 700;
}

.carousel {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 2%;
  margin-bottom: 3%;
  overflow: hidden;
}
.carouselText {
  text-align: left;
  padding-top: 10%;
  width: 40%;
}

.carouselText h1 {
  font-size: 3.5rem;
  font-weight: 700;
}

.carouselText h5 {
  font-size: 1.25rem;
  font-weight: 800;
  color: #025a63;
}
.carouselImg {
  width: 50% !important;
  position: relative;
  animation: slide 1.5s steps(100);
}
@keyframes slide {
  100% {
    right: -960px;
  }
}

@media (max-width: 880px) {
  .carousel {
    flex-direction: column;
    align-items: center;
  }

  .carouselText {
    width: 65%;
    text-align: center;
  }

  .carouselText h1 {
    font-size: 2.5rem;
  }

  .carouselText h5 {
    font-size: 1rem;
    font-weight: 700;
  }

  .carouselImg {
    width: 65% !important;
  }
}

.conceal {
  visibility: hidden;
}

.aboutus {
  text-align: center;
  margin-bottom: 8%;
}

.borderbreak {
  margin-top: -100px;
  padding: 10px;
  padding-left: 20px;
  background: #fff;
  width: 300px;
  margin-left: 35%;
}
.borderbreak h1 {
  font-size: 50px;
}
h1 {
  font-size: 3rem;
}
.btnalign {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cssbtn {
  background: #000;
}

.subservice {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 5%;
}

.h1class {
  color: #025a63;
  text-align: center;
}

.service {
  margin-bottom: 4%;
}

.servicediv h2 {
  font-weight: 700;
  font-size: 1.75rem;
  color: #025a63;
}

.subservice img {
  width: 10rem;
  height: 10rem;
  margin-top: -38%;
  margin-bottom: 1rem;
}
@media screen and (max-width: 500px) {
  .subservice img {
    width: 8rem;
    height: 8rem;
    margin-top: -25%;
  }
}

.servicediv {
  padding: 1%;
  margin: 4%;
  width: 26%;
  border: 0.1rem solid #83dbe1;
}

.OurServiceLogo {
  border: 0.1rem solid rgb(131, 218, 224);
  border-radius: 50%;
  background: #fff;
}
.servicedivision {
  background: #c8dbe1;
  border-radius: 0;
  padding-bottom: 3rem;
  text-align: center;
}

#servhed {
  color: #025a63;
  margin-bottom: 3rem;
}

.addproperty {
  position: relative;
  background: #eff7f9;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  margin: 2% 0% 2% 25%;
  padding: 2%;
  overflow: hidden;
  /* animation: slide0 2s ease-in-out; */
}
@keyframes slide0 {
  0% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(0%);
  }
}

.searchproperty {
  position: relative;
  background: #eff7f9;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 4% 25% 2% 0%;
  padding: 2%;
  overflow: hidden;
  /* animation: slide1 2.5s linear; */
}
@keyframes slide1 {
  0% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(0%);
  }
}
.servhead {
  font-size: 1.75rem;
  color: #025a63;
  margin-bottom: 1.5rem;
}

.servicecontent {
  padding: 1rem;
}

.serviceimage {
  width: 20%;
  object-fit: contain;
  transition: 1s ease;
}

.serviceimage:hover {
  transform: translateY(-1rem);
  cursor: pointer;
}

.serviceimage2:hover {
  transform: translateY(-1rem);
  cursor: pointer;
}

.serviceimage2 {
  width: 20%;
  object-fit: contain;
  transition: 1s ease;
}

.servicebtn,
.addpropertybtn {
  border-radius: 1.5rem;
  background: #339999;
  color: #fff;
  border: none;
  font-size: 1.25rem;
  font-weight: 700;
  transition: 0.5s ease;
  text-align: left;
  padding: 0.5rem 2.5rem;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.2);
}
.servicebtn:hover {
  letter-spacing: 0.1rem;
}

.addpropertybtn {
  background: #3cd4a5;
  box-shadow: 0 0.5rem 2.5rem 1rem rgba(0, 0, 0, 0.2);
}

@media (max-width: 880px) {
  .aboutus {
    margin-bottom: 11%;
  }
  .borderbreak h1 {
    font-size: 1.75rem;
  }
  h1 {
    font-size: 1.5rem;
  }
  .servicediv {
    width: 75%;
    margin-top: 12%;
  }

  .subservice {
    flex-direction: column;
    align-items: center;
  }

  .subservice img {
    width: 9rem;
    height: 9rem;
    margin-top: -18%;
  }

  .borderbreak {
    margin-top: -45px;
    padding: 10px;
    background: #fff;
    width: 200px;
    margin-left: 5px;
  }
}

/* Outercontact */
.outercontact {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding: 0 6% 3%;
}

.innercontact1 {
  background: #c8dbe1;
  float: left;
  width: 45%;
  padding: 3rem 1rem;
}

.img_container {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.innercontact1 .img_container img {
  width: 18rem;
  object-fit: contain;
  margin-bottom: 3rem;
}

.aligned {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.start {
  font-size: 1rem;
  text-align: left;
  margin: 0 0.5rem;
}

.innercontact2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #025a63;
  width: 55%;
  padding: 2rem 1rem;
}

.contactushead h3 {
  color: #fff;
  text-align: center;
  margin: 0.5rem;
}
.hh3 {
  font-size: 50px;
}

.alignment {
  display: flex;
  flex-direction: row;
}

.test {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputcss {
  height: 3rem;
  margin: 0.5rem;
  border: 0.1rem solid black;
  border-radius: 0.3rem;
  width: 45%;
}
.css {
  width: 93%;
}

.centeralg {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

@media (max-width: 880px) {
  .aligned {
    margin-left: 0.5rem;
  }
  .innercontact1 {
    width: 100%;
    margin: 0;
    padding: 1rem;
  }

  .innercontact1 img {
    margin-left: 10px;
  }

  .innercontact2 {
    width: 100%;
    margin: 0;
  }
  .outercontact {
    flex-direction: column;
  }

  /*fonts*/

  .hh3 {
    font-size: 40px;
  }
  .servicebtn {
    font-size: 13px;
  }
}

.footer-distributed {
  background: #025a63;
  box-shadow: 0 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 1rem sans-serif;
  padding: 3.5rem 1rem;
  text-align: center;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
  display: inline-block;
  vertical-align: top;
}

.footer-distributed h3 {
  color: #ffffff;
  margin-bottom: 1rem;
  font-weight: 700;
}

/* Footer left */

.footer-distributed .footer-left {
  width: 40%;
  cursor: pointer;
  vertical-align: center !important;
}

.footer-distributed .footer-icons {
  margin-top: 25px;
}
.footer-icons a {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  background-color: #3cd4a5;
  border-radius: 0.2rem;
  font-size: 1.25rem;
  color: #08043a;
  text-align: center;
  line-height: 2rem;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  transition: all 0.5s;
}

.footer-icons a:hover {
  background: #025a63;
  transform: scale(1.02);
  border: 0.1rem solid#3cd4a5;
}

/* The company logo */

/* .footer-distributed h3 span {
  color: lightseagreen;
} */

/* Footer links

.footer-distributed .footer-links {
  color: #ffffff;
  margin: 1.25rem 0 0.75rem;
  padding: 0;
}

.footer-distributed .footer-links a {
  display: inline-block;
  line-height: 1.8;
  font-weight: 400;
  text-decoration: none;
  color: inherit;
}

.footer-distributed .footer-company-name {
  color: #222;
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
} */

/* Footer Center */

.footer-distributed .footer-center {
  width: 30%;
  cursor: pointer;
  color: #fff;
  line-height: 1.75rem;
}
.footer-center li {
  transition: 1s;
}
.footer-center li:hover {
  transform: translateX(0.75rem);
}

.footer-center ul {
  list-style: none;
  padding: 0;
}

/* .footer-distributed .footer-center i {
  background-color: #33383b;
  color: #ffffff;
  font-size: 1.5rem;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  text-align: center;
  line-height: 2.75rem;
  margin: 0.8rem 1rem;
  vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
  font-size: 1rem;
  line-height: 2.25rem;
}

.footer-distributed .footer-center p {
  display: inline-block;
  color: #ffffff;
  font-weight: 400;
  vertical-align: middle;
  margin: 0;
}

.footer-distributed .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 1rem;
  line-height: 2;
}

.footer-distributed .footer-center p a {
  color: lightseagreen;
  text-decoration: none;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight: 300;
  font-size: 20rem;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
} */

/* Footer Right */

.footer-distributed .footer-right {
  width: 30%;
  cursor: pointer;
}

.footer-right {
  color: #fff;
  text-align: center;
}
.footer-right ul {
  line-height: 1.75rem;
  color: #fff;
  list-style: none;
  padding: 0;
}

.footer-right li {
  transition: 1s;
}
.footer-right li:hover {
  transform: translateX(0.75rem);
}

/* 
.footer-distributed .footer-company-about {
  line-height: 20px;
  color: #92999f;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-company-about span {
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
} */

.copyright {
  text-align: center;
  color: #fff;
  font-size: 1rem;
  margin-bottom: -1.75rem;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {
  .getintouch {
    margin: 5%;
  }
  .footer-distributed {
    font: bold 1rem sans-serif;
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 2.5rem;
    text-align: center;
  }

  .footer-distributed .footer-center i {
    margin-left: 0;
  }
  .inputstyle {
    width: 80%;
  }
  .query {
    width: 90%;
  }

  .borderbreakget {
    margin-top: -40px;
    padding: 10px;
    background: #fff;
    width: 200px;
    font-size: 20px;
    margin-left: 10%;
  }
}

/*footer ends*/
.alignment {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.submitbtn {
  padding: 10px;
  font-weight: bold;
  border-radius: 5px;
  background: #151b47;
  color: #fff;
}
.loginheading {
  text-align: center;
  margin-bottom: 3%;
}
.logincss {
  margin-top: 40%;
}

.textdec {
  text-decoration: none;
  color: #fff;
}
@media screen and (max-width: 600px) {
  .alignment {
    flex-direction: column;
    align-items: center;
  }
  .alignment > img {
    width: 60%;
  }
  .alignment > div {
    text-align: center;
    width: 60%;
  }
  .names div:nth-child(1) {
    margin-left: 2%;
  }
}

@media (max-width: 400px) {
  .postpropertyheading {
    padding: 0 !important;
  }
  .postpropli {
    padding: 0;
  }
}
