.logo {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.logo img {
  width: 50px;
  height: 50px;
}
nav {
  background: #001a68;
  overflow: hidden;
}
nav:after {
  content: "";
  clear: both;
  display: table;
}
nav ul {
  z-index: 1;
  float: right;
  list-style: none;
  margin-right: 40px;
  position: relative;
}
nav ul li {
  float: left;
  display: inline-block;
  margin: 0 5px;
}
nav ul li a {
  font-weight: 900;
  color: white;
  text-decoration: none;
  line-height: 70px;
  font-size: 18px;
  padding: 8px 15px;
  transition: 0.3s ease;
}
nav ul li a:hover {
  font-weight: 700;
  color: #fff;
}
nav a {
  font-family: "Merienda", cursive;
}
nav ul ul {
  position: absolute;
  top: 90px;
  opacity: 0;
  visibility: hidden;
  transition: top 0.3s;
}
nav ul li:hover > ul {
  top: 70px;
  opacity: 1;
  visibility: visible;
}
nav ul ul li {
  position: relative;
  margin: 0px;
  width: 220px;
  float: none;
  display: list-item;
}
nav ul ul ul li {
  position: relative;
  top: -70px;
  left: 150px;
}
nav ul ul li a {
  line-height: 50px;
}
nav .logo {
  float: left;
  color: white;
  font-size: 27px;
  font-weight: 600;
  line-height: 70px;
  padding-left: 60px;
}
.login {
  border-radius: 5px;
  box-shadow: 0 0 5px #fff, 0 0 5px #fff;
}

nav ul li ul li {
  background: linear-gradient(135deg, #6713d2 10%, #cc208e 100%);
  margin-right: 5%;
}
nav ul li ul li a {
  margin: 4%;
}
h6 {
  font-family: "Merienda", cursive;
}

.show,
.icon,
.hide {
  display: none;
}
@media all and (max-width: 786px) {
  nav ul {
    margin-right: 0px;
    float: left;
  }
  nav .logo {
    padding-left: 30px;
    width: 100%;
  }
  nav ul li,
  nav ul ul li {
    display: block;
    width: 100%;
  }
  nav ul ul {
    top: 70px;
    position: static;
    float: none;
    display: none;
    opacity: 1;
    visibility: visible;
  }
  nav ul ul ul li {
    position: static;
  }
  nav ul ul a {
    padding-left: 40px;
  }
  nav ul ul ul a {
    padding-left: 80px;
  }
  .show {
    display: block;
    color: white;
    font-size: 18px;
    padding: 0 20px;
    line-height: 70px;
    cursor: pointer;
  }
  .show:hover {
    color: cyan;
  }
  .icon {
    display: block;
    color: white;
    position: absolute;
    right: 40px;
    line-height: 70px;
    font-size: 25px;
    cursor: pointer;
  }
  nav ul li a:hover {
    box-shadow: none;
  }
  .show + a,
  .ulclass {
    display: none;
  }
  [id^="btn"]:checked + ul {
    display: block;
  }
}

/*navbar ends*/

h1 {
  margin-top: 2%;
  color: #001a68;
  font-weight: 800;
}

.postproperty {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  margin-top: 0%;
  background-color: white;
}
.postpropertyText {
  text-align: left;
  padding-top: 10%;
  width: 50vh;
  height: 80vh;
}
.postpropertyText h1 {
  font-size: 60px;
  font-weight: 700;
}

.postpropertyText h5 {
  font-size: 20px;
  font-weight: 800;
  color: #001a68;
}

.postpropertyImg img {
  height: 500px;
  width: 500pxpx;
}

@media (max-width: 880px) {
  .postproperty {
    flex-direction: column;
  }

  .postpropertyText h1 {
    font-size: 40px;
  }

  .postpropertyText h6 {
    font-size: 15px;
    font-weight: 700;
  }

  .postpropertyImg img {
    height: 300px;
    width: 350pxpx;
  }
}

/*post-property ends*/

/*dashboard starts*/

/*dashboard ends*/
h1 {
  margin-top: 2%;
  color: #001a68;
  font-weight: 800;
}
.proptype {
  margin-top: 20px;
}

.subtype {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 5%;
  height: auto;
}
.typebtn {
  border: 4px solid #173eb3;
  border-radius: 6px;
  background: #001a68;
  color: #fff;
  font-weight: 700;
  width: 150px;
  height: 150px;
  margin: 5%;
}

@media (max-width: 880px) {
  .subtype {
    flex-direction: column;
  }
}

/*propertytype ends*/

.container1 {
  max-width: 500px;
  max-height: 10000px;
  padding: 20px;
  background-color: EBEBEB;
  box-shadow: 0 2px 5px #001a68;
  margin: 20px auto;
  opacity: 1;
  color: #001a68;
  line-height: 49px;
  align-items: left;
}

.inputstyle1 {
  width: 95%;
  height: 40px;
  margin: 1%;
  margin-top: 5%;
}

placeholder {
  color: #001a68;
}

label {
  margin: 2%;
}

.dropdown {
  width: 95%;
  height: 40px;
  margin: 1px;
}

/* Radio buttons */
input[type="radio"] {
  font-size: 60px;
  margin: 1%;
}

/*.radiobtn{
	margin: 0;
}*/

.description {
  margin-top: 5%;
  height: 80px;
  width: 95%;
}

.box1 {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding: 5%;
  height: auto;
}
.inputstyle2 {
  width: 20%;
  height: 40px;
}
