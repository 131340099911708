.outerbox {
  max-height: 25vh;
}

.inbox {
  width: 100%;
  padding: 1%;
}

.inbox img {
  height: 50%;
  width: 90%;
  margin: 0 auto;
}

.featurealg {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
