/*add property pg*/

:root {
  --blue: #1e90ff;
  --white: #ffffff;
}

.logo {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.logo img {
  width: 50px;
  height: 50px;
}
nav {
  background: #001a68;
  overflow: hidden;
}
nav:after {
  content: "";
  clear: both;
  display: table;
}
nav ul {
  z-index: 1;
  float: right;
  list-style: none;
  margin-right: 40px;
  position: relative;
}
nav ul li {
  float: left;
  display: inline-block;
  margin: 0 5px;
}
nav ul li a {
  font-weight: 900;
  color: white;
  text-decoration: none;
  line-height: 70px;
  font-size: 18px;
  padding: 8px 15px;
  transition: 0.3s ease;
}
nav ul li a:hover {
  font-weight: 700;
  color: #fff;
}
nav a {
}
nav ul ul {
  position: absolute;
  top: 90px;
  opacity: 0;
  visibility: hidden;
  transition: top 0.3s;
}
nav ul li:hover > ul {
  top: 70px;
  opacity: 1;
  visibility: visible;
}
nav ul ul li {
  position: relative;
  margin: 0px;
  width: 220px;
  float: none;
  display: list-item;
}
nav ul ul ul li {
  position: relative;
  top: -70px;
  left: 150px;
}
nav ul ul li a {
  line-height: 50px;
}
nav .logo {
  float: left;
  color: white;
  font-size: 27px;
  font-weight: 600;
  line-height: 70px;
  padding-left: 60px;
}
.login {
  border-radius: 5px;
  box-shadow: 0 0 5px #fff, 0 0 5px #fff;
}

nav ul li ul li {
  background: linear-gradient(135deg, #6713d2 10%, #cc208e 100%);
  margin-right: 5%;
}
nav ul li ul li a {
  margin: 4%;
}
h6 {
}

.show,
.icon,
.hide {
  display: none;
}
@media all and (max-width: 786px) {
  nav ul {
    margin-right: 0px;
    float: left;
  }
  nav .logo {
    padding-left: 30px;
    width: 100%;
  }
  nav ul li,
  nav ul ul li {
    display: block;
    width: 100%;
  }
  nav ul ul {
    top: 70px;
    position: static;
    float: none;
    display: none;
    opacity: 1;
    visibility: visible;
  }
  nav ul ul ul li {
    position: static;
  }
  nav ul ul a {
    padding-left: 40px;
  }
  nav ul ul ul a {
    padding-left: 80px;
  }
  .show {
    display: block;
    color: white;
    font-size: 18px;
    padding: 0 20px;
    line-height: 70px;
    cursor: pointer;
  }
  .show:hover {
    color: cyan;
  }
  .icon {
    display: block;
    color: white;
    position: absolute;
    right: 40px;
    line-height: 70px;
    font-size: 25px;
    cursor: pointer;
  }
  nav ul li a:hover {
    box-shadow: none;
  }
  .show + a,
  .ulclass {
    display: none;
  }
  [id^="btn"]:checked + ul {
    display: block;
  }
}

/*navbar ends*/
.addImgContainer2 {
  width: 90%;
  margin: auto;
}
.imginput {
  width: 90%;
  margin-top: 5%;
}

.labeladdimg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.divbuttonaddimg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonaddimg {
  border-radius: 15% !important;
  padding: 1%;
  margin: 1%;
  background: #c8dbe1;
}

.buttonaddimg:hover {
  background-color: #025a63;
  color: #fff;
}

.postproperty h1 {
  margin-top: 2%;
  color: white;
  font-weight: 800;
  position: relative;
  animation: slide 1.5s steps(12);
}
@keyframes slide {
  100% {
    bottom: 360px;
  }
}

.postproperty p {
  color: white;
}

.postproperty {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
  height: 86vh;
  overflow: hidden;
}
.postpropertyText {
  text-align: center;
  padding-top: 10%;
  width: 50vh;
  height: 80vh;
}
.postpropertyText h1 {
  font-size: 60px;
  font-weight: 700;
}

.postpropertyText h5 {
  font-size: 20px;
  font-weight: 800;
}

.postpropertyText p,
h5 {
  color: #001a68;
}

.postpropertyText h1 {
  color: #001a68;
}

.servicebtn {
}
.servicebtn:hover {
  letter-spacing: 1px;
  background-color: gray;
}

.postpropertyImg img {
  height: 400px;
  width: 500pxpx;
  transition: 0.4s;
}
.postpropertyImg1 img {
  height: 300px;
  width: 250px;
  margin-top: 10px;
}
.postpropertyImg2 img {
  height: 380px;
  width: 250px;
  transition: 0.5s;
}

.postpropertyImg2 img:hover {
  margin-top: 20px;
}

.postpropertyImg img:hover {
  transform: scale3d(1.1, 1.1, 1);
}

.postpropertyImg3 img:hover {
}

.postpropertyImg7 img {
  height: 200px;
  width: 250px;
  padding-left: 150px;
}

.postpropertyImgBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.postpropertyImg3 img {
  height: 150px;
  width: 150px;
  transition: 0.5s;
}

.postpropertyImg5 img {
  height: 150px;
  width: 150px;
}

.postpropertyImg4 img {
  height: 200px;
  width: 100px;
  transform: translateX(60px);
}

.postpropertyImg6 img {
  height: 350px;
  width: 300px;
  margin-left: -250px;
}

@media (max-width: 880px) {
  .postproperty {
    flex-direction: column;
  }

  .postpropertyText h1 {
    font-size: 40px;
  }

  .postpropertyText h6 {
    font-size: 15px;
    font-weight: 700;
  }

  .postpropertyImg img {
    height: 300px;
    width: 350pxpx;
  }
}

/*post-property ends*/

/*dashboard starts*/

.dashboard,
.postproperty {
  overflow-x: hidden;
  margin-bottom: 4%;
}
.dashboard h1 {
  margin-top: 5%;
  color: #025a63;
  font-weight: 800;
  text-align: center;
}

.container {
  display: grid;
  grid-template-columns: repeat(4, 20%);
  flex-wrap: wrap;
  column-gap: 1rem;
  justify-content: center;
  flex-direction: row;
  padding: 3%;
  align-items: center;
}
.box {
  margin: 5%;
  height: 150px;
  background-color: #025a63;
  text-align: center;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box h1 {
  color: white;
  font-size: 1.75rem;
}

/*dashboard ends*/

@media screen and (max-width: 800px) {
  .container {
    grid-template-columns: repeat(2, 40%);
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .box {
    border-radius: 0.5rem;
    height: 125px;
  }

  .box h1 {
    font-size: 1.25rem;
  }
}
.proptype {
  margin-top: 2%;
  color: #001a68;
  font-weight: 800;
  text-align: center;
}
.proptype {
  margin-top: 20px;
}
.proptype h2 {
  color: #001a68;
  text-align: center;
}

.subtype {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 5%;
  height: auto;
}

.borderbreak {
  margin-top: -95px;
  padding: 10px;

  background: #fff;
  width: 300px;
  margin-left: 35%;
  align-items: center;
}
.borderbreak h1 {
  font-size: 50px;
}
h1 {
  font-size: 50px;
}
.subtype img {
  width: 150px;
  height: 150px;
  margin-top: -70px;
}

.servicediv {
  padding: 1%;
  margin: 5%;
  width: 350px;
  border: 8px solid #001a68;
  border-radius: 15px;
  align-items: center;
}
.servicediv h2 {
  font-weight: 700;
  font-size: 30px;
  color: #001a68;
}

.typebtn {
  border: 4px solid #001a68;
  border-radius: 6px;
  background: #001a68;
  color: #fff;
  font-weight: 700;
  margin: 5px;
  font-weight: 700;
  transition: 0.5s ease;
  padding: 0;
}
.typebutton {
  border: 4px solid #001a68;
  border-radius: 6px;
  color: #fff;
  font-weight: 700;
  text-decoration: solid;
  list-style: none;
  padding: 10px;
  transition: 1s;
  font-size: 15px;
}

.typebutton:hover {
  letter-spacing: 1px;
  background-color: gray;
}

.typebtn:hover {
  letter-spacing: 1px;
  background-color: gray;
}

.borderbreakimg {
  margin-top: -35px;
  padding: 10px;

  background: #fff;
  width: 100px;
  margin-left: 25%;
  align-items: center;
}

@media (max-width: 880px) {
  .borderbreakimg {
    margin-top: -35px;
    padding: 20px;

    background: #fff;
    width: 100px;
    margin-left: 25%;
  }
  .borderbreak h1 {
    font-size: 30px;
  }
  h1 {
    font-size: 25px;
  }
  .servicediv {
    margin-top: 24%;
  }
  .subtype {
    flex-direction: column;
  }
}

/*propertytype ends*/

.container1 {
  max-width: 500px;
  max-height: 10000px;
  padding: 20px;
  background-color: EBEBEB;
  box-shadow: 0 2px 5px #001a68;
  margin: 20px auto;
  opacity: 1;
  color: #001a68;
  line-height: 49px;
  align-items: left;
}

.inputstyle1 {
  width: 95%;
  height: 40px;
  margin: 1%;
  margin-top: 5%;
}

placeholder {
  color: #001a68;
}

label {
  margin: 2%;
}

.dropdown {
  width: 95%;
  height: 40px;
  margin: 1px;
}

/* Radio buttons */
input[type="radio"] {
  font-size: 60px;
  margin: 1%;
}

/*.radiobtn{
	margin: 0;
}*/

.description {
  margin-top: 3%;
  height: 80px;
  width: 82%;
  margin-left: 29px;
  margin-bottom: 30px;
}

.box1 {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding: 5% 7% 5% 5%;
  height: auto;
}
.inputstyle2 {
  width: 25%;
  height: 2rem;
  text-align: center;
}

.sectiondiv {
  padding: 2rem 0;
}

.labeldiv {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px #025a63;
  width: 60%;
  height: 65vh;
  cursor: pointer;
  text-align: center;
}

.labeldiv h1 {
}

.labeldiv span {
  font-weight: bold;
  font-size: large;
  padding-top: 0.5rem;
}

.selectImage img {
  padding: 0;
  margin: 0;
}

.selectImages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.selectImage {
  margin: 1rem 0.75rem;
  position: relative;
  box-shadow: #001a68;
}

.selectImage button {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  border: none;
  color: white;
  background-color: #001a68;
}

.selectImage button:hover {
  background-color: #173eb3;
}

.selectImage p {
  margin: 0;
  text-align: center;
  padding-right: 20%;
}

.upload-btn {
  cursor: none;
  margin: 1%;
  padding: 1%;
  display: block;
  margin: 0 auto;
  border: none;
  width: 32%;
  height: 3rem;
  color: #025a63;
  background-color: #ebebeb;
}

.labeldiv label:hover.upload-btn:hover {
  box-shadow: #025a63;
}

.error {
  text-align: center;
}

.error span {
  color: red;
  font-size: large;
}

.delete {
  margin-left: 0.5rem !important;
  width: 1.5rem;
  height: 1.5rem;
}

.containerpopup {
  border: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  padding-left: 90px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 50px;
  margin-top: -200px;
  margin: 60px 300px;
}
.dashboardh {
  margin-bottom: -500px;
  margin: 0 100px;
}
.containerpopup img {
  width: 280px;
}
.rightalign {
  padding: 40px;
  display: flex;
  margin-left: 200px;
  flex-direction: column;
}
.dashbtn {
  border: none;
  padding: 10px;
  background: #025a63;
}

.dashbtn a {
  text-decoration: none;
  font-size: 20px;
  color: #fff;
}

@media (max-width: 880px) {
  .containerpopup {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 15px;
  }

  .containerpopup img {
    width: 100px;
  }
  .rightalign {
    margin: 0;
    align-items: center;
    padding: 20px;
  }

  .dashbtn {
    width: 100px;
    padding: 0px;
    font-size: 15px;
  }
  .rbgcontainer {
    margin-left: 3%;
  }
}

@media (max-width: 600px) {
  .labeldiv {
    width: 80%;
    overflow-x: hidden;
  }

  .labelh2,
  .labelspan,
  .imginput,
  .error,
  .error span {
    font-size: 0.8rem !important;
  }
  .buttonaddimg,
  .upload-btn {
    padding: 1%;
    margin: 2% auto;
  }
  .delete {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.25rem !important;
  }
}

@media (max-width: 400px) {
  .imginput {
    font-size: 0.6rem !important;
  }
}
