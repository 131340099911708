/*parking type 2wheeeler 4wheelr dropdown with condition of selected parking element*/

/*electric charges to add in rent form*/
:root {
  --blue: #1e90ff;
  --white: #ffffff;
}

.usericon {
  width: 50px;
  height: 50px;
  margin-left: 150px;
  margin-top: 5px;
  border-radius: 50%;
}

.hiddentext {
  display: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
input,
select {
  font-size: 18px;
}
label {
  font-size: 15px;
}

.backgroundimg {
  background-color: #c8dbe1;
  padding: 3% 5%;
}

.maindiv {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);
  background: var(--white);
  margin: auto;
  border-radius: 0.75rem;
}

.innerdivision {
  margin: 2% 3%;
  padding-top: 3%;
}

.setcolor {
  background-color: #025a63;
  padding: 1%;
  text-align: center;
  border-radius: 0.25rem;
}
.white {
  color: white;
  margin-left: 10px;
  font-size: 25px;
}
.white1 {
  color: white;
}

.red {
  color: red;
  letter-spacing: 0.075rem;
}

.iconheading {
  font-weight: bold;
  color: #808080;
  margin-left: 4%;
  margin-top: 6%;
  margin-bottom: 2%;
}

.HostelInfo {
  background: #ebebeb;
  padding: 3%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.space-ar {
  display: flex;
  justify-content: space-between;
  padding-left: 7%;
  padding-top: 2%;
  padding-bottom: 1%;
}

.space-ar1 {
  padding-left: 50px;
  display: flex;
  justify-content: space-between;
  height: 40px;
}

.space-ar2 {
  padding-left: 50px;
  padding-bottom: 80px;
  display: flex;
  justify-content: space-between;
  height: 40px;
}

.space-ar3 {
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  margin-top: -80px;
}

.space-ar4 {
  padding-left: 50px;
  display: flex;
  justify-content: space-between;
  height: 40px;
}

.button-groups {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 10px;
}
.button-groups div {
  background: #025a63;
  padding: 3%;
  text-transform: uppercase;
  color: #fff;
  border-radius: 10px;
}

.Amenities {
  background: #ebebeb;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.RoomInfo {
  background: #ebebeb;
  padding: 2%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.OwnerDetailInfo {
  background: #ebebeb;
  padding: 3%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.rbgcol {
  flex-direction: column !important;
}

.PropertyInfo {
  background: #ebebeb;
  padding: 2%;
  padding-top: 4%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.PropertyInfo select {
  width: 38%;
}

.backgroundimg h5 {
  margin: 2%;
  font-weight: bold;
}

.inputcss {
  width: 40%;
  border: none;
  border-radius: 0px;
  background: #ebebeb;
}
.inputcss1 {
  margin-right: 6%;
  width: 20%;
  border: none;
  background: #ebebeb;
  border-radius: 0px;
  height: 50px;
}
.inputcss2 {
  margin-right: 70px;
  width: 20%;
  border: none;
  background: #ebebeb;
}
.inputcss3 {
  margin-right: 70px;
  width: 20%;
  border: none;
  background: #ebebeb;
  height: 50px;
}
.inputcss4 {
  margin-right: 10px;
  width: 20%;
  border: none;
  background: #ebebeb;
  padding: 0%;
}
.inputcss5 {
  margin-left: 8%;
  width: 1000px;
  border: none;
  background: #ebebeb;
}
.inputcss6 {
  width: 35% !important;
  border: none;
  background: #ebebeb;
  padding: 0%;
  margin-left: 6%;
  margin-bottom: 4%;
  background: none;
}

.inputcss9 {
  margin-right: 70px;
  width: 70% !important;
  border: none;
  background: #ebebeb;
  padding: 0%;
  margin-left: 30px;
  background: none;
  margin-top: 30px;
  height: 50px;
  width: 400px;
}

.inputcss7 {
  margin-right: 70px;
  width: 20%;
  border: none;
  background: #ebebeb;
  padding: 0%;
  background: none;
  margin-top: 30px;
  height: 50px;
  width: 400px;
}
.inputcss8 {
  margin-right: 70px;
  width: 70%;
  border: none;
  background: #ebebeb;
  padding: 0%;
  margin-left: 30px;
  background: none;
  height: 50px;
}

#inputid1 {
  border: none;
  width: 400px;
  border-bottom: 1px solid black;
  margin-top: 10px;
  height: 50px;
}

input::placeholder {
  color: grey;
}
input[type="radio"]:checked + label {
  background: #025a63;
  color: #ebebeb;
}
.radiobuttongroup {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 50%;
}

.best-suited {
  width: 50%;
}

.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  margin-left: 2%;
}
.wrapper .option {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 3%;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.option span {
  background: #fff;
  padding: 6px 15px;
  border-radius: 5px;
}
.wrapper .option .dot {
  height: 20px;
  width: 20px;
  background: #fff;
  border-radius: 50%;
}
.wrapper .option .dot::before {
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #025a63;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
input[type="radio"] {
  display: none;
}

#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2,
#option-3:checked:checked ~ .option-3 {
  background: #025a63;
}
#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot,
#option-3:checked:checked ~ .option-3 .dot {
  background: #fff;
}
#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before,
#option-3:checked:checked ~ .option-3 .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span {
  font-size: 20px;
  color: black;
}
#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span,
#option-3:checked:checked ~ .option-3 span {
  color: #fff;
  background: #025a63;
}

#option-4:checked:checked ~ .option-4 .dot,
#option-5:checked:checked ~ .option-5 .dot {
  color: #fff;
  background: #025a63;
}
#option-4:checked:checked ~ .option-4 .dot::before,
#option-5:checked:checked ~ .option-5 .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span {
  font-size: 20px;
  color: black;
}
#option-4:checked:checked ~ .option-4 span,
#option-5:checked:checked ~ .option-5 span {
  color: #fff;
  background: #025a63;
}
#option-4:checked:checked ~ .option-4 .dot,
#o #option-6:checked:checked ~ .option-6,
#option-7:checked:checked ~ .option-7 {
  border-color: #025a63;
  background: #025a63;
}
#option-6:checked:checked ~ .option-6 .dot,
#option-7:checked:checked ~ .option-7 .dot {
  background: #fff;
}
#option-6:checked:checked ~ .option-6 .dot::before,
#option-7:checked:checked ~ .option-7 .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span {
  font-size: 20px;
  color: black;
}
#option-6:checked:checked ~ .option-6 span,
#option-7:checked:checked ~ .option-7 span {
  color: #fff;
  background: #025a63;
}

#option-8:checked:checked ~ .option-8,
#option-9:checked:checked ~ .option-9 {
  border-color: #025a63;
  background: #025a63;
}
#option-8:checked:checked ~ .option-8 .dot,
#option-9:checked:checked ~ .option-9 .dot {
  background: #fff;
}
#option-8:checked:checked ~ .option-8 .dot::before,
#option-9:checked:checked ~ .option-9 .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span {
  font-size: 20px;
  color: black;
}
#option-8:checked:checked ~ .option-8 span,
#option-9:checked:checked ~ .option-9 span {
  color: #fff;
  background: #025a63;
}

#option-10:checked:checked ~ .option-10,
#option-11:checked:checked ~ .option-11 {
  border-color: #025a63;
  background: #025a63;
}
#option-10:checked:checked ~ .option-10 .dot,
#option-11:checked:checked ~ .option-11 .dot {
  background: #fff;
}
#option-10:checked:checked ~ .option-10 .dot::before,
#option-11:checked:checked ~ .option-11 .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span {
  font-size: 20px;
  color: black;
}
#option-10:checked:checked ~ .option-10 span,
#option-11:checked:checked ~ .option-11 span {
  color: #fff;
  background: #025a63;
}

#option-12:checked:checked ~ .option-12,
#option-13:checked:checked ~ .option-13 {
  border-color: #025a63;
  background: #025a63;
}
#option-12:checked:checked ~ .option-12 .dot,
#option-13:checked:checked ~ .option-13 .dot {
  background: #fff;
}
#option-12:checked:checked ~ .option-12 .dot::before,
#option-13:checked:checked ~ .option-13 .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span {
  font-size: 20px;
  color: black;
}
#option-12:checked:checked ~ .option-12 span,
#option-13:checked:checked ~ .option-13 span {
  color: #fff;
  background: #025a63;
}

.right {
  float: right;
}
select {
  border: none;
  font-weight: bold;
  margin: 5% 6%;
  padding: 3%;
}
.continuebtn {
  float: center;
  padding-top: 30px;
  text-align: center;
  padding-bottom: 30px;
}
.continue {
  border-radius: 2%;
  padding: 10px;
  border-radius: 5px;
  background: #025a63;
  color: #fff;
}
.checkk {
  visibility: hidden;
}
#check:checked:checked ~ .checkk span {
  color: #fff;
}
input[type="checkbox"] {
  visibility: hidden;
}

input[type="checkbox"]:checked {
}

/* the slider bar */

/* the slider from the label */
.checkbox-example label {
  transition: all 0.5s ease;
  cursor: pointer;
  font-size: 18px;
  background: #fff;
  padding: 8px;
  border-radius: 5px;
}

/* Move the slider if the checkbox is clicked */
.checkbox-example input[type="checkbox"]:checked + label {
  background: #025a63;
  color: #fff;
}
#icontxt {
  font-size: 15px;
  text-align: center;
  margin-top: 10px;
}

#icontxt1 {
  font-size: 15px;
  text-align: center;
  margin-top: 10px;
  margin-right: 30px;
}

.inputgrp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-left: -3%;
  border: none;
}
.inputgrp .label {
  border-radius: 50%;
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  overflow: hidden;
}

.inputgrp .checkbox-example2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputgrproom {
  margin-top: -100px;
}
.inputgrproom1 {
  margin-top: -20px;
}

#roomid {
  font-weight: normal;
  font-size: 17px;
  text-align: center;
  display: inline;
  margin-top: 10px;
}
#facilityid {
  white-space: nowrap;
  font-weight: normal;
  font-size: 17px;
  color: black;
  margin-left: 8%;
  margin-top: 5%;
}
.inputgrpcls1 label {
  margin-left: 3%;
}

.inputgrpcls2 label {
  margin-left: 60px;
}
#inputid {
  border: none;
  border-bottom: 1px solid black;
  width: 40%;
}
#inputid2 {
  border: none;
  border-bottom: 1px solid black;
  width: 400px;
  margin-top: 30px;
}
#inputid3 {
  border: none;
  border-bottom: 1px solid black;
  width: 90%;
  margin-top: 5%;
}

#inputid4 {
  margin-bottom: 28px;
  border: none;
  border-bottom: 1px solid black;
  width: 87%;
}

#inputid5 {
  border: none;
  border-bottom: 1px solid black;
  width: 400px;
  margin: 10px;
}

.inputgrp label img {
  width: 60px;
  height: 60px;
}
.delet {
  width: 30px;
  height: 30px;
}
.propertymanager {
  margin-left: 25px;
}
.addnew {
  border: none;
  background: #025a63;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px 30px;
  text-align: center;
  margin-top: 20px;
}

.commonarea {
  font-size: 19px;
  color: black;
}
label img {
  height: 50px;
}

::placeholder {
  color: grey;
  font-size: 16px;
  padding-left: 3px;
}

.bestsuited {
  height: 50px;
  width: 200px;
}
.addresscss {
  padding-left: 60px;
  padding-top: 10px;
  padding-bottom: 40px;
}

.formcss input {
  border: 1px solid black;
  padding: 8px 10px;
  margin-top: -90px;
  width: 90%;
}

.formcss input::placeholder {
  color: gray;
}

/*login and register*/
.addImagesContainer {
  min-height: 100vh;
}
.LoginContainer {
  width: 90%;
  overflow-y: hidden;
  margin: auto;
  max-height: 100vh;
}
.alignment > div {
  width: 40%;
}
.authbox1,
.authbox2 {
  padding: 3%;
}

.authbox1 img,
.authbox2 img {
  width: 55%;
  margin-right: 1%;
}

.cont .logincss {
  margin-top: 0%;
}

.names {
  display: flex;
}
.names input {
  width: 80%;
  margin-right: 5px;
}

#smalltxt {
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
}
.logbtn {
  width: 80%;
  padding: 10px 0;

  background: #025a63;
  color: #fff;
  border: none;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.centeralgreg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shiftrgt {
  transform: translateX(50px);
}

#facilityhed {
  font-size: 14px;
  margin-top: 50px;
  margin-left: 70px;
  font-weight: 400;
}

/*fonts*/

#pid {
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  display: inline;
  margin-top: 5%;
}

#pidun {
  margin-right: 10px;
}
#pid1 {
  white-space: nowrap;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  display: inline;

  margin-top: 10px;
}

#tt {
  margin-left: 29px;
  margin-top: 40px;
}
#lang {
  margin-top: 30px;
}

#checkboxOneInput30 {
  margin-right: 100px;
}

.rbgcontainer {
  padding-left: 6%;
}

.otherdetaildesc {
  height: 3rem;
}

@media all and (max-width: 675px) {
  .space-ar {
    flex-direction: column;
  }

  .maindiv {
    width: 98%;
  }

  #inputid1,
  #inputid,
  #inputid2,
  .input,
  .inputcss,
  .inputcss1,
  .inputcss2,
  .inputcss3,
  .inputcss5,
  .inputcss6 {
    width: 200px;
    padding: 0;
    margin: 0;
  }
  .addresscss {
    padding-left: 0px;
  }
  #inputid {
    margin: 0;
    padding: 0;
  }
  .inputcss6 {
    margin-left: 0px;
  }
  .inputgrp .label {
    height: 40px;
    width: 40px;
  }
  #icontxt {
    font-size: 9px;
  }
  #icontxt1 {
    margin-left: -50px;
    font-size: 10px;
  }

  .wrapper .option .dot {
    height: 10px;
    width: 10px;
  }

  .checkbox-example label {
    font-size: 10px;
  }

  .checkbox-example2 {
    font-size: 10px;
    margin-left: -120px;
  }

  .checkbox-example2 label {
    width: 10px;
    height: 10px;
  }

  .checkbox-example2 #icontxt {
    margin-left: -120px;
  }

  .inputgrp {
    padding: 0 0;
    margin: 0;
  }
  .space-ar3 {
    display: flex;
    flex-direction: column;
    margin-left: -40px;
  }

  input::placeholder {
    font-size: 15px;
  }

  .wrapper .option span {
    font-size: 13px;
  }
  #pid {
    font-size: 15px;
  }

  .wrapper {
    width: 65%;
    margin-top: 1px;
  }

  #inputid4 {
    margin: 0;
  }
  .best-suited {
    padding-top: 9%;
  }

  .space-ar,
  .space-ar1 {
    display: flex;
    flex-direction: column;
    padding-left: 4%;
    width: 90%;
  }

  .space-ar1 {
    margin-bottom: 120px;
  }
  .meals {
    margin-bottom: 100px;
  }

  .hostel {
  }

  .inputcss8 {
    margin-left: 0px;
  }
  #roomid,
  #facilityid {
    font-size: 15px;
    margin-left: 0;
  }
  .space-ar2 {
    margin-bottom: 60px;
  }
  .inputgrproom1 {
    margin-left: 50px;
    margin-top: 0px;
    padding-top: 40px;
  }

  .inputgrpcls1 {
    margin-left: 40px;
  }

  .room-input label {
    margin-left: -190px;
    padding: 6px;
  }

  .faci-input label {
    margin-left: -190px;
  }

  .faci-input2 label {
    margin-left: -190px;
  }
  .facilitygrp {
    margin-left: 200px;
  }
  .best-suited {
    margin-right: 100px;
  }

  .checkbox-example label {
    padding: 6px;
  }

  .radiobuttongroup {
    width: auto;
  }
  .space-ar select {
    margin: 5% 0;
  }

  .rbgcol {
    flex-direction: row;
  }

  .inputcss5 {
    margin-left: 4%;
  }

  .space-ar.inputgrp {
    flex-direction: row !important;
  }

  #facilityid {
    margin-left: 6% !important;
  }

  .checkbox-example {
    width: 25%;
  }

  .inputcss6#inputid {
    margin-left: 6%;
    margin-bottom: 4%;
  }
}

@media (max-width: 400px) {
  .space-ar {
    width: 100% !important;
    padding-left: 0% !important;
  }
}
