:root {
  --blue: #1e90ff;
  --white: #ffffff;
}

.mostouterdiv {
  display: flex;
  justify-content: center;
  width: 70%;
}
.outerdiv {
  width: 80%;
  display: flex;
  justify-content: center;
}

.space {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  column-gap: 1%;
  justify-content: space-evenly;
  align-items: center;
  margin: 3% 0;
  padding: 2%;
}

.space1 {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  justify-content: space-evenly;
  align-items: center;
  margin: 3% 0;
  padding: 2%;
}

.contentboxx3 {
  width: 100%;
  margin-bottom: 75px;
  border-radius: 10px;
}

.contentboxx {
  width: 100%;
  margin-bottom: 6%;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);
}

.contentboxx .propertyimg {
  width: 500px;
  height: 200px;
  margin: 10px;
}

.contentboxx1 {
  width: 100%;
  margin-bottom: 6%;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);
}

.contentboxx2 {
  width: 100%;
  margin-bottom: 6%;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);
  padding: 1%;
}

.contentlayout {
  margin: auto;
  width: 90%;
}

.carouselclass img {
  width: 100%;
  height: 100%;
}
.showicon {
  border: 4px solid #001a68;
  padding: 30px;
  border-radius: 5px;
  margin: 10px;
}

.space button {
  border: none;
  padding: 10px;
  border-radius: 5px;
}
.innerdiv {
  display: flex;
  justify-content: start;
}
.contentboxx h5 {
  font-weight: 300;
}
.contentboxx p {
  font-weight: 300;
}
.specifications {
  padding: 3%;
  margin: auto;
  background: #025a63;
  color: #fff;
  border-radius: 5px;
  text-align: center;
}
.slider {
  display: flex;
  flex-direction: row;
}
.showpropertycarousel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.sph4 {
  font-size: 1.75rem;
  margin: 2%;
}

.sph5 {
  font-size: 1.25rem;
  text-align: center;
}

.sppara {
  font-size: 1.25rem;
  line-height: 1.5;
}

.sppara2 {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.5;
}

.spacediv {
  padding: 2%;
}

@media (max-width: 750px) {
  .sph4 {
    font-size: 1rem;
  }
  .sph5 {
    font-size: 0.75rem;
  }
  .sppara,
  .sppara2 {
    font-size: 1rem;
  }
  .space {
    column-gap: 1%;
    row-gap: 5%;
    grid-template-columns: repeat(2, 50%);
  }
  .space1 {
    grid-template-columns: repeat(3, 33%);
  }
}

@media (max-width: 500px) {
  .contentlayout {
    width: 95%;
  }
  .specifications,
  .sppara {
    font-size: 0.75rem;
  }
  .space1,
  .space {
    column-gap: 1%;
    row-gap: 5%;
    grid-template-columns: repeat(2, 40%);
  }
}
